<template>
  <div>
    <div class="flex justify-between">
      <h4 class="text-lg font-bold">今日剩余库存</h4>
    </div>

    <div>
      <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeTypeId">
        <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
      </a-tabs>
    </div>

    <base-table ref="saleGoods"
                id="saleGoods"
                :columnsData="columns"
                :rowKey="rowKey"
                :row-selection="rowSelection"
                :tableData="tableData"
                :getListFunc="initData"
                :noPage="true"
                :addHeight="10"
                :page.sync="page"
                :pageSize.sync="page_count">
      <template #toolbar>
        <div>
          <div class="flex justify-between mt-1 mb-2">
            <a-button type="link" class="mr-2" size="small" style="padding:0;"
                :disabled="!(rowSelection && rowSelection.selectedRowKeys.length)"
                @click="handleMultiStock">批量修改库存</a-button>
            <a-button type="link" class="mr-2" size="small" style="paddng:0;"
                @click="handlerSave">保存库存数据</a-button>
          </div>
        </div>
      </template>
      <template #max_stock="{ text }">
        <span>{{ text > 999 ? "999+" : text }}</span>
      </template>
      <template #current_stock="{ record }">
        <!-- <a-input-number v-model="record.current_stock" /> -->
        <a-input-number type="number" :min="0" style="width:100%;"
            v-model="record.current_stock" />
      </template>
    </base-table>

    <a-modal :visible.sync="isShowModal"
             title="批量设置当前库存"
             destroyOnClose
             width="30%"
             @ok="handleOk"
             @cancel="isShowModal=false">
      <div class="flex items-center">
        <span>今日剩余库存</span>
        <a-input-number type="number" :min="0" v-model="batchStock" 
             class="ml-3" style="width:120px;"/>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { rowKey, formatType } from "./columns"
import { rowSpan } from "@/utils/index"
import { getSaleGoodsList, editGoodsData } from "@/api/shop.js"
import { goodsType } from "@/utils/type"
import { cutQuery } from '@/utils/ossImg'

export default {
  data () {
    return {
      username: this.$store.getters["name"],
      rowKey,
      isShowModal: false,
      columns: [],
      baseColumns: [
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 100,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品分类",
          dataIndex: "goods_type",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            return {
              children: formatType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.flavor_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        {
          title: "最大库存",
          dataIndex: "max_stock",
          align: "center",
          width: 100,
          slots: { customRender: "max_stock" },
        },
        {
          title: "今日剩余库存",
          dataIndex: "current_stock",
          align: "center",
          width: 130,
          slots: { customRender: "current_stock" },
        },
      ],
      isShowSaveAndCancel: true,
      isEdit: false,
      isShowAddGoods: false,
      delivery_limit: 0,
      base_delivery_limit: 0,
      total: 0,
      batchStock: 0,
      typeList: [
        { type_id: "", type_name: "全部" }
        // { type_id: 1, type_name: "蛋糕" },
        // { type_id: 0, type_name: "其他商品" },
      ],
      activeTypeId: "",

      typeSubList: [],
      tableData: [],
      selectKeys: [],
      page: 1,
      page_count: 300,
      rowSelection: {
        selectedRowKeys: [],
      },
      baseRowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.sku_id);
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.sku_id
            );
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.sku_id
            );
          } else {
            this.rowSelection.selectedRowKeys = [];
          }
        },
      },
    }
  },

  async mounted () {
    for(let id in goodsType){
      if(id != 3){
        this.typeList.push({
          type_id: id,
          type_name: goodsType[id],
        })
      }
    }

    this.columns = this.baseColumns
    // this.handlerTypeChange(this.activeKey)
    this.rowSelection = this.baseRowSelection
    this.columns = this.baseColumns
    this.initData()
  },
  methods: {

    handlerTypeChange(type_id){
      this.activeTypeId = type_id
      // if(type_id == 3){ // 蛋糕
      //   this.handlerCancelEdit()
      // }
      this.initData(type_id)
    },

    async initData (goods_type) {
      const { data, code } = await getSaleGoodsList({
        goods_type: goods_type||"", // 无用
        page: this.page,
        page_count: this.page_count,
      })
      if (code == 0) {
        if (data.delivery_limit) {
          this.delivery_limit = data.delivery_limit
          this.base_delivery_limit = data.delivery_limit
        }
        this.total = data.total_count
        this.selectKeys = data.list.map((el) => {
          return el.goods_id
        })

        // 组装表格所需数据
        let skuArr = []
        // data.list.filter(gd=>gd.goods_type!=3).map((goods) => {
        data.list.map((goods) => {
          // goods.is_cake = goods.goods_type==3
          goods.sku_data.map((item) => {
            let obj = goods
                obj.flavor_name = item.flavor_name
                obj.status = 1
            // if (obj.is_cake) {
            //   obj.max_stock = "999+"
            // }
            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, "goods_name")
        // skuArr = rowSpan(skuArr, 'flavor_name')

        this.tableData = skuArr
      }
    },

    // handlerTypeChange (e) {
    //   this.activeKey = e
    //   if (this.activeKey == 1) {
    //     this.columns = this.columns.filter(
    //       (el) => el.dataIndex != "current_stock"
    //     )
    //     this.rowSelection = null
    //   } else {
    //     this.rowSelection = this.baseRowSelection
    //     this.columns = this.baseColumns
    //   }
    //   this.initData()
    // },


    // 批量处理
    handleMultiStock () {
      if (!this.rowSelection.selectedRowKeys.length) {
        this.$message.warning("请先勾选要更新库存的商品");
        return;
      }
      this.isShowModal = true
    },

    handleOk () {
      const params = {}
      params.update_sku_data = JSON.stringify(
        this.rowSelection.selectedRowKeys.map((item) => {
          return {
            sku_id: item,
            current_stock: this.batchStock,
          }
        })
      )
      params.off_shelves_goods_data = JSON.stringify([])
      editGoodsData(params).then((res) => {
        if (res.code === 0) {
          this.isShowModal = false
          this.$message.success("批量更新库存成功")
          this.initData()
        }
      })
    },

    handlerSave () {
      let sku_list = this.tableData.map((item) => {
        return {
          sku_id: item.sku_id,
          max_stock: item.max_stock,
          current_stock: item.current_stock,
        }
      })
      if(sku_list.length){
        let params = {
          update_sku_data: JSON.stringify(sku_list)
        }
        editGoodsData(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("库存已更新")
            this.initData()
          }
        })
      } 
    },
  },
}
</script>
